import { Injectable } from '@angular/core';
import { Paginator } from '@app/classes';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import { HireQuestion } from '@models/employee/hire-question.model';
import { QuestionColumn } from './columns';

const editOption: MenuColumnItem = {
    label: 'edit',
    event: 'edit',
};

const deleteOption: MenuColumnItem = {
    label: 'delete',
    event: 'delete',
};

@Injectable()
export class NewHireQuestionsTable extends Table<HireQuestion> {
    showMenuColumnOptions = true;

    constructor(protected auth: AuthService) {
        super(auth);
        this.boot();
    }

    getMenuColumnOptionsForRow(_: HireQuestion): MenuColumnItem[] {
        return [editOption, deleteOption];
    }

    setColumns(): void {
        this.columns = [new QuestionColumn()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(HireQuestion.param('company', this.auth.company.id));
    }
}
